/* eslint-disable no-unused-vars */
<template>
  <div class="container">
    <div class="header">
      <img :src="
          require('../../../static/img/solution/' + dataItem.headImg + '.png')
        " alt="" />
      <div class="header-title">{{ dataItem.headTitle }}</div>
    </div>
    <div class="solution">
      <div class="title d-f j-c a-a">
        <div class="line1"></div>
        <div class="small-square"></div>
        <div class="big-square"></div>
        <div class="title-info">{{ dataItem.headTitle }}</div>
        <div class="big-square"></div>
        <div class="small-square"></div>
        <div class="line2"></div>
      </div>
      <div class="solution-content d-f wow bounceInLeft">
        <div class="solution-content-left">
          <img :src="
              require('../../../static/img/solution/' +
                dataItem.imgInfo +
                '.png')
            " alt="" />
        </div>
        <div class="solution-content-right d-f">
          <div class="content-l-title">{{ dataItem.title1 }}</div>
          <div class="content-l-info" v-html="dataItem.info1"></div>
        </div>
      </div>
    </div>
    <div class="design">
      <div class="title d-f j-c a-a">
        <div class="line1"></div>
        <div class="small-square"></div>
        <div class="big-square"></div>
        <div class="title-info">{{ dataItem.solutionTitle2 }}</div>
        <div class="big-square"></div>
        <div class="small-square"></div>
        <div class="line2"></div>
      </div>
      <div class="design-content">
        <div class="wow bounceInLeft">
          <div class="team1 d-f">
            <div class="card" v-for="(item, i) in dataItem.teamObj1" :key="i">
              <div class="card-title">{{ item.title }}</div>
              <div class="card-info">{{ item.msg }}</div>
            </div>
          </div>
          <div class="team2 d-f">
            <div class="card" v-for="(item, i) in dataItem.teamObj2" :key="i">
              <div class="card-title">{{ item.title }}</div>
              <div class="card-info">{{ item.msg }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      index: 0,
      dataArr: [
        //政府行业信息化  0
        {
          headTitle: "政府行业信息化",
          headImg: "gov",
          imgInfo: "govinfo",
          title1: "政府行业信息化",
          info1: `<p>政府行业信息化就是应用现代信息和通信技术，将管理
                        和服务通过网络技术进行集成，以及对正度需要和拥有的信
                        息资源的开发和管理，对提高政府工作效率、决策质量、调
                        控能力、廉洁程度、节约政府开支，改进政府的组织结构、
                        业务流程和工作方式，全方位向社会民众提供超越时间、空
                        间与部门分隔的限制的优质管理和服务。</p>
                        <p>全面推进政府信息化，将有效提升执政能力的效率化、
                        公开化，管理机制的程序化、人性化，践行科学发展观，建
                        设一个先进的服务型、责任性政府。</p>`,
          solutionTitle2: "政府行业信息化方案设计",
          teamObj1: [
            {
              title: "智能会议",
              msg:
                "智能会议系统支持与办公OA系统、中控系统、会议预约系统、庭审系统、综合管理平台等系统无缝对接，实现统一管控，互联互通。",
            },
            {
              title: "智能安防",
              msg:
                "智慧安防将遍布各种角落的信息资源整合在一起，通过统一管理和分析使其发挥作用，帮助政府实时了解各方面情况，及时响应和处理。",
            },
          ],
          teamObj2: [
            {
              title: "智能门禁",
              msg:
                "基于人脸识别，对接各类闸机、考勤机等实现一站式成品落地，主要通过摄像头采集人脸信息，与后台人脸库对比，确认可以自由通行。",
            },
            {
              title: "一站式解决方案",
              msg:
                "无缝融合语音、视频和数据信息，实现了多路信号的统一处理丰富信息获取手段，精准辅助决策。",
            },
          ],
        },
        //人脸识别 1
        {
          headTitle: "人脸识别解决方案",
          headImg: "face",
          imgInfo: "faceinfo",
          title1: "人脸识别系统",
          info1: `<p>人脸识别系统包含人脸检测与属性分析、人脸对比、人
                        脸搜索、活体检测等能力。灵活应用于金融、泛安防、零售
                        等行业场景，满足身份核验、人脸考勤、闸机通行等业务需
                        求。</p><p>人脸实名认证结合身份证识别、人脸对比、活体检测等
                        多项组合能力，连接权威数据源，确保用户是「真人」且为
                        「本人」，快速完成用户身份核验，可应用于金融服务、物
                        流货运等行业，有效控制业务风险，抵御作弊行为，降低人
                        工监督成本。</p>`,
          solutionTitle2: "人脸识别方案设计",
          teamObj1: [
            {
              title: "异常报警",
              msg:
                "人脸识别报警解决方案主要用于身份识别。利用视频监控对一定范围内的用户在非配合状态下的进行快速身份识别，可以远距离快速确认人员身份，实现智能预警。",
            },
            {
              title: "人脸校验",
              msg:
                "人脸校验可以快速检测人脸并返回人脸框位置，准确识别多种属性信息，然后提取人脸特征，对比两张人脸相似度并返回评分，判断是否为同一个人。",
            },
          ],
          teamObj2: [
            {
              title: "门禁管理",
              msg:
                "通过200W宽动态摄像头根据面部特征采集人脸数据，对社区内部人员进行出入管理，业主可通过刷脸开锁。业主也可通过手机APP完成远程开锁功能。",
            },
            {
              title: "多元化使用场景",
              msg:
                "人脸识别使用场景包括：人脸实名认证、刷脸闸机通行、智慧人脸考勤、智能视频监控、刷脸移动支付、智能会员管理、人脸注册登录等。",
            },
          ],
        },
        //智慧校园 2
        {
          headTitle: "智慧校园解决方案",
          headImg: "school",
          imgInfo: "schoolInfo",
          title1: "智慧校园",
          info1: `<p>智慧校园物联网平台可支持多样化的大屏展示服务，满
                        足不同用户需求。直观展示校园整体运行状态。</p><p>教室智能管控系统，便于老师采用多形式的互动教学
                        方法，帮助老师更简单、轻松地开展个性化教学、探索式教
                        学。用电设备智能管控可对灯光等设备开关进行定时。在控
                        制方式上节约能源时，还能体现系统的人性化。例如学校放
                        假，统一时间段关闭教学楼栋的灯光。不止于灯光管控，智
                        慧校园还可对设备使用情况实时监测统计，异常情况及时呈
                        现告警报表，帮助管理人员快速定位故障，进行故障排查。</p>`,
          solutionTitle2: "智慧校园方案设计",
          teamObj1: [
            {
              title: "校务管理",
              msg:
                "智能会议系统支持与办公OA系统、中控系统、会议预约系统、庭审系统、综合管理平台等系统无缝对接，实现统一管控，互联互通。",
            },
            {
              title: "应用系用平台",
              msg:
                "采用统一的技术架构，将各种应用系统的通用功能提取出来，使用组件的方式实现，使系统的开发工作集中在业务的实现，大大减轻了系统开发和维护成本。",
            },
          ],
          teamObj2: [
            {
              title: "IT硬件基础设施",
              msg:
                "智慧业务一体机提供集成式预部署方案，将少量智慧业务集成到1-4个预部署和IT整体交付机柜中，降低项目实施整体复杂度，推动业务迅速上线和后续平稳运行。",
            },
            {
              title: "集中解决方案",
              msg:
                "不止于灯光等设备开关进行定时管控，还对设备使用情况实时监测统计，异常情况及时呈现告警报表，帮助管理人员快速定位故障，进行故障排查。",
            },
          ],
        },
        //智慧宿舍 3
        {
          headTitle: "智慧宿舍解决方案",
          headImg: "room",
          imgInfo: "roomInfo",
          title1: "智慧宿舍",
          info1: `<p>为了有效提升学校学生宿舍的安全及管理效率， 在所
                        有的宿舍楼出入口安装了无障碍通道，通过人脸识别技术，
                        快速识别进入人员，对于权限内人员直接放行， 对于外来
                        人员或者无权限通道关闭，并提示报警声，提示管理人员
                        进行处置。 管理系统中，记录所有通行人员图像资料记录。</p><p>系统实时统计所有宿舍楼内人数、 违规人数、学生晚
                        归次数、学生未归次数等信息， 学校管理人员及时获得真
                        实住宿状况数据，进行相应处置， 同时有效避免学校管理
                        人员查寝的麻烦，减少工作量。</p>`,
          solutionTitle2: "智慧宿舍方案设计",
          teamObj1: [
            {
              title: "基础信息管理",
              msg:
                "对基础资源池进行集中调度、集中管理实时监控整个计算资源平台的运行状况，并通过云计算和虚拟化技术实现应用于数据的备份与动态迁移。",
            },
            {
              title: "智能查寝",
              msg:
                "运用了目前先进的人脸识别技术和数据分析，结合学院宿管系统和学生管理系统，及时准确的将每个同学进出寝数据推送给班主任及相关学生负责人。",
            },
          ],
          teamObj2: [
            {
              title: "白名单管理",
              msg:
                "依据白名单上的人员进行录入识别。白名单上的人员是指按学校要求和各单位工作需要，每个工作日都要到校工作的在职正式职工和外聘人员。",
            },
            {
              title: "智能通道",
              msg:
                "智能通道的快速通过率高、无权限人员通过时报警、具有防尾随功能、支持选配刷卡、实时显示学生信息、自动统计进出人员，有效防止学生代替刷卡等。",
            },
          ],
        },
        //智慧社区 4
        {
          headTitle: "智慧社区解决方案",
          headImg: "community",
          imgInfo: "communityInfo",
          title1: "智慧社区",
          info1: `<p>智慧社区应用将三维地理信息、互联网、大数据、人工
                        智能技术与社区服务深度融合，建设集智慧管理、智慧民
                        生、智慧业务、智慧治理为一体的智慧社区平台，实现了基
                        层政府精细化管理、社区网格化治理、居民便利化生活，为
                        社区居民提供便捷的公共服务等便民服务。</p><p>智慧社区整合了社区周边一公里的使用商户，实现
                        B2B2C电子商务，配送上门，主营业务为生鲜水果、餐饮
                        美食以及日用百货等等，同时会组织一些地方特产等团购活
                        动。</p>`,
          solutionTitle2: "智慧社区方案设计",
          teamObj1: [
            {
              title: "社区信息",
              msg:
                "细化业务应用软件设计的同时加强和完善系统基础应用平台的设计，建立一个安全的、易于扩展的精细化管理服务基础平台。",
            },
            {
              title: "物业管理",
              msg:
                "通过节目制作、节目发布、终端管理、播放统计等功能，实现社区宣传、广告的统一部署和管理，有效支撑社区的业务经营和增值运营。",
            },
          ],
          teamObj2: [
            {
              title: "智能访客",
              msg:
                "为不同的访客定制不同的预约信息内容，提供详细的导航和接待导引，与前台信息同步。设置访客黑名单，防止不良分子侵入。",
            },
            {
              title: "智慧办公",
              msg:
                "在移动设备上随时随地利用碎片化时间，组织24小时运行。各岗位可基于具体工作目标点或事项快速部门之间无区域限制的通力协作。",
            },
          ],
        },
        //智慧安防 5
        {
          headTitle: "智慧安防解决方案",
          headImg: "headerimg",
          imgInfo: "info",
          title1: "智慧安防",
          info1: `<p>智慧社区应用将三维地理信息、互联网、大数据、人工
                        智能技术与社区服务深度融合，建设集智慧管理、智慧民
                        生、智慧业务、智慧治理为一体的智慧社区平台，实现了基
                        层政府精细化管理、社区网格化治理、居民便利化生活，为
                        社区居民提供便捷的公共服务等便民服务。</p>
                        <p>智慧社区整合了社区周边一公里的使用商户，实现
                        B2B2C电子商务，配送上门，主营业务为生鲜水果、餐饮
                        美食以及日用百货等等，同时会组织一些地方特产等团购活
                        动。</p>`,
          solutionTitle2: "智慧安防方案设计",
          teamObj1: [
            {
              title: "陌生人管理",
              msg:
                "利用人脸识别技术与软件技术，实现完全智能和具有高安全性的身份鉴别验证系统，配合自动化门控装置进行校园出入口通行控制。",
            },
            {
              title: "智能报警",
              msg:
                "视频联网报警系统，是运行非常成熟，运用非常广的自主开发的核心系统平台之一，开启了网络数字安防运营管理中心技术的新纪元。",
            },
          ],
          teamObj2: [
            {
              title: "校园智能安防",
              msg:
                "公共区域不出现监控盲区，在面积较大的公共区域宜安装具有转动和变焦放大功能的摄像机或多台摄像机，通过监控屏应能辨别监视范围内的人员活动情况。",
            },
            {
              title: "智能社区安防",
              msg:
                "通过人脸数据、门禁通行数据和案件数据进行关联分析，对犯罪嫌疑人进行轨迹刻画、精准布控，快速定位、有效打击。",
            },
          ],
        },
      ],
    };
  },
  computed: {
    dataItem: function () {
      return this.dataArr[this.index];
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route (to, from) {
      this.index = this.$route.params.id - 1;
      this.init();
    },
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      new this.$wow.WOW().init();
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  // overflow: hidden;
  width: 100%;
  min-width: 1200px;
  .header {
    position: relative;
    img {
      width: 100%;
      height: 2.1875rem;
      // position: absolute;
    }
    .header-title {
      position: absolute;
      top: 0.916667rem;
      left: 2.072917rem;
      min-width: 240px;
      height: 0.348958rem;
      font-size: 0.25rem;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 0.348958rem;
      color: #ffffff;
      opacity: 0.87;
    }
  }
  .solution {
    .solution-content {
      height: 2.244792rem;
      .solution-content-left {
        width: 50%;
        height: 100%;
        img {
          width: 100%;
        }
      }
      .solution-content-right {
        width: 50%;
        height: 100%;
        flex-direction: column;
        opacity: 1;
        border-radius: 4px;
        padding: 0 1.880208rem 0 0.411458rem;
        .content-l-title {
          font-size: 0.125rem;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 0.171875rem;
          color: #333333;
          opacity: 1;
          letter-spacing: 1px;
          &::after {
            content: "";
            display: block;
            width: 0.208333rem;
            height: 3px;
            background: #000000;
            opacity: 0.87;
            margin: 0.104167rem 0;
          }
        }
        .content-l-info {
          width: 2.864583rem;
          height: 1.604167rem;
          font-size: 0.104167rem;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 0.182292rem;
          color: #333333;
          // letter-spacing: 60px;
          text-indent: 2em;
          opacity: 1;
        }
      }
    }
  }
  .design {
    .design-content {
      padding: 0 1.828125rem;
      padding-bottom: 0.348958rem;
      // height: 3.348958rem;
      opacity: 1;
      background-image: url("../../../static/img/solution/bg1.png");
      background-repeat: no-repeat;
      // background-attachment: fixed;
      background-size: 100% 100%;
      .team1 {
        justify-content: space-between;
        margin-bottom: 0.0625rem;
        .card {
          width: 3.140625rem;
          height: 1.34375rem;
          background-size: 100% 100%;
          background-image: url("../../../static/img/solution/card1.png");
          &:nth-child(2) {
            background-image: url("../../../static/img/solution/card2.png");
          }
        }
      }
      .team2 {
        justify-content: space-between;
        // margin-bottom: 0.0625rem;
        .card {
          width: 3.140625rem;
          height: 1.34375rem;
          background-size: 100% 100%;
          background-image: url("../../../static/img/solution/card3.png");
          &:nth-child(2) {
            background-image: url("../../../static/img/solution/card4.png");
          }
        }
      }
    }
  }
}
.card {
  padding: 0.192708rem 0.203125rem;
  .card-title {
    // width: 126px;
    // height: 33px;
    font-size: 0.125rem;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 0.171875rem;
    color: #ffffff;
    // letter-spacing: 60px;
    opacity: 1;
    border-bottom: 1px solid rgba(255, 255, 255, 0.38);
    padding-bottom: 0.104167rem;
  }
  .card-info {
    // width: 525px;
    // height: 104px;
    font-size: 0.104167rem;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 0.197917rem;
    color: #ffffff;
    // letter-spacing: 60px;
    opacity: 0.87;
    padding-top: 0.109375rem;
  }
}
.title {
  height: 1.067708rem;
  .big-square {
    width: 0.088542rem;
    height: 0.088542rem;
    background: #4285f4;
    opacity: 1;
    margin: 10px;
    transform: rotate(45deg);
  }
  .small-square {
    width: 0.052083rem;
    height: 0.052083rem;
    background: #4285f4;
    opacity: 0.38;
    transform: rotate(45deg);
  }
  .title-info {
    font-size: 0.166667rem;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 45px;
    color: #000000;
    opacity: 0.87;
  }
  .line1 {
    width: 40px;
    height: 2px;
    background: linear-gradient(
      270deg,
      rgba(66, 133, 244, 0.38) 0%,
      rgba(66, 133, 244, 0) 100%
    );
    opacity: 1;
    margin-right: 4px;
  }
  .line2 {
    width: 40px;
    height: 2px;
    background: linear-gradient(
      270deg,
      rgba(66, 133, 244, 0) 0%,
      rgba(66, 133, 244, 0.38) 100%
    );
    opacity: 1;
    margin-left: 4px;
  }
}
</style>